import './App.scss';
import InputMask from 'react-input-mask';

import l1 from './img/l_1.svg';
import l2 from './img/l_2.svg';
import l3 from './img/l_3.svg';
import logo from './logo.svg';

import i1 from './img/1.jpg';
import i2 from './img/2.jpg';

import qr_df from './img/qr_df.png';
import qr_tg from './img/qr_tg.png';

import win from './img/win.svg';
import mac from './img/mac.svg';
import vr from './img/vr.svg';
import { useState } from 'react';


function App() {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [comment, setComment] = useState('')

  const [submitted, setSubmitted] = useState(false)

  const sendContact = (d) => {

    setSubmitted(true)
    console.log(name,
      email,
      phone,
      comment)

    fetch('https://web-30.ru/api/add-contact', {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        phone,
        comment
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return false;
  }

  return (
    <>
      <div className="container">
        <header>
          <img src={l1} alt='Финтех Хаб' className="logo" />
          <img src={l2} alt='Финополис' className="logo" />
          <img src={l3} alt='ВТБ' className="logo" />
          <img src={logo} alt='Лого' className="logo" />
        </header>
        <div className="block-1">
          <h1>Метаверс ВТБ</h1>
          <div className="cols">
            <div className="text">
              <p>
                Платформа и набор инструментов<br />
                для создания иммерсивных сценариев
                пользовательского взаимодействия
                и уникального цифрового опыта.
              </p>
              <p>
                Активно тестируется в банке<br />
                для проведения командных встреч
                и сессий. В нашем фокусе — создание
                классных иммерсивных инструментов
                коммуникации для команд банка.
              </p>
            </div>
            <div className="img-block">
              <img src={i1} alt="" className="img" />
              <div className="title title_right">
                <img src={logo} alt="" className="logo_small" />
                <span className="info">
                  Конференция в Метаверс ВТБ
                </span>
              </div>
            </div>
          </div>
          <div className="block-btn">
            <a href='#platforms' className="btn">Попасть в Метаверс</a>
          </div>
        </div>
        <div className="block-2">
          <div className="cols">
            <div className="img-block">
              <img src={i2} alt="" className="img" />
              <div className="title title_left">
                <img src={logo} alt="" className="logo_small" />
                <span className="info">
                  Конструктор аватаров в Метаверс ВТБ
                </span>
              </div>
            </div>
            <div className="text">
              <p>
                Платформа объединяет несколько
                виртуальных локаций. Пользователям
                доступен конструктор 3D-аватаров
                и возможность персонализировать
                своего  «цифрового двойника».
              </p>
              <p>
                Подходит для организации
                виртуальных событий «емкостью»
                до 50  участников.
              </p>
            </div>
          </div>
        </div>
        <div className="block-3">
          <div className="cols">
            <div className="text">
              <div className="qr">
                <div className="qr-text">
                  В апреле 2024 ВТБ представил рынку собственную
                  метавселенную на конференции
                  Data Fusion.
                </div>
                <div className="qr-data">
                  <img src={qr_df} alt="" className="qr-img" />
                  <span className="qr-info">
                    Как это было
                    посмотреть можно
                    здесь или по QR коду
                  </span>
                </div>
              </div>
            </div>
            <div className="img-block">
              <iframe className="img" width="635" height="360" src="https://video.dion.vc/video/embed/ef3e9901-3c2c-469f-a19b-ec40d2cdd3f4" title="DION Видео player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowFullScreen></iframe>
              <div className="title title_right">
                <img src={logo} alt="" className="logo_small" />
                <span className="info">
                  Конференция Data Fusion
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="block-4" id='platforms'>
          <div className="text">
            <h2>Скачать</h2>
            <p>
              Скачайте дистрибутив<br />
              и познакомьтесь с платформой<br />
              прямо сейчас
            </p>
          </div>
          <div className="platforms">
            <div className="platform">
              <div className="title active">
                Windows
              </div>
              <img className="logo" src={win} alt='win' />
              <div className="action">
                <a href='https://disk.yandex.ru/d/cqW2MzAtqO0gug' className="btn">Скачать</a>
              </div>
            </div>
            <div className="platform">
              <div className="title">
                Mac OS
              </div>
              <img className="logo" src={mac} alt='mac' />
              <div className="action">
                <span>В разработке</span>
              </div>
            </div>
            <div className="platform">
              <div className="title">
                VR-платформы
              </div>
              <img className="logo" src={vr} alt='vr' />
              <div className="action">
                <span>В разработке</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="block-5">
        <div className="">
          <div className="cols">
            <div className="">
              <h2>Контакты</h2>
              <div className="qr">
                <div className="qr-text">
                  Команда с радостью ответит<br />
                  на ваши вопросы, любая обратная<br />
                  связь приветствуется!
                </div>
                <div className="qr-data">
                  <img src={qr_tg} alt="" className="qr-img" />
                  <span className="qr-info">
                    Больше наших новостей<br />
                    в Telegram-канале
                  </span>
                </div>
              </div>
            </div>
            <div>
              {!submitted && <form onSubmit={(d) => sendContact(d)} className="form">
                <input
                  type="text"
                  value={name}
                  name="name"
                  onChange={e => setName(e.target.value)}
                  placeholder='Имя'
                  required
                />
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  placeholder='Email'
                  required
                />
                <InputMask
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  id="phone"
                  name="phone"
                  mask="+7(999)999-99-99"
                  maskChar="_"
                  placeholder='Телефон'
                  required
                />
                <textarea
                  value={comment}
                  name="comment"
                  onChange={e => setComment(e.target.value)}
                  placeholder='Комментарий'
                  id=""
                ></textarea>
                <button className="btn">
                  Отправить
                </button>
              </form>}

              {submitted && <div className="success">
                Ваше обращение отправлено, мы свяжемся с Вами позже.
                <p>✓</p>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
